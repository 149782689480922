<template>
  <div class=" fill-height">
    <div class="contentArea">
      <div class="charting white text-center">
        <EmojiAreaBeck :emoji="emojiInterpretations" :gague_color="gaugeColor" class="my-10"
                       :title-interpretation="titleInterpretation" :max-score="report.result[0].max_score"
                       :score="score" :report="report"/>
      </div>
      <div class=" my-10 charting white text-center">
        <v-row>
          <v-col cols="12" lg="12" class="" style="margin: 0px auto">
            <div class="gauge">
              <vue-svg-gauge
                  :start-angle="-90"
                  :end-angle="90"
                  :value="score"
                  :separator-step="0"
                  :min="report.result[0].min_score"
                  :max="report.result[0].max_score"
                  :gauge-color="gaugeColor"
                  :scale-interval="0"
              />
              <div class="gaugeInfo " style="margin-top: 20px" :style="{'color':gaugeColor}">
                {{ score }}
              </div>
            </div>
            <div class="textInterpretationTitle mt-10">
              {{ titleInterpretation }}
            </div>
            <div class="singleInterpretation" v-for="(item , n) in report.reports" :key="n">
              <div class="shortInterpretation">
                <div class="titleShortInterpretation">
                </div>
                <div v-html="item.report.description_for_client_fa"></div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="6">
          </v-col>
        </v-row>
      </div>
      <div class="charting white mt-15  text-center pa-10">
        <div v-if="ai_response == 'loader'" class="">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <br>
          درحال دریافت اطلاعات از هوش‌مصنوعی
        </div>
        <div v-else>
          <div class="labelAi">تفسیر هوش‌مصنوعی</div>
          <template v-if="ai_response.length != 0">
            <div class="text-right   general_ai_interpretation text-justify">
              {{ ai_response.general_interpretation }}
              <ol class="text-right font-weight-bold mt-2 mr-10">
                <li v-for="(advice,i) in ai_response.advice" :key="i">
                  {{ advice }}
                </li>
              </ol>
            </div>

          </template>
          <template v-else>
            <div v-if="isLoggedIn">
              <h4>
                تفسیر بیشتر با هوش مصنوعی
              </h4>
              <p class="mt-4">
                شما می‌توانید با کمک دستیار هوش مصنوعی سیمیاروم، نتیجه تست خود را بیشتر تفسیر کنید تا از جزئیات بیشتری
                در
                مورد تفسیر خود با مطلع شوید.
              </p>
              <v-btn
                  color="primary"
                  elevation="0"
                  class=" mt-1"
                  @click="getAiResponse"
              >
                دریافت تفسیر از هوش‌مصنوعی
              </v-btn>
            </div>
            <div v-else>
              <RegisteringSection @afterLogin="getAiResponse"/>
            </div>
          </template>

        </div>
      </div>
      <div class="mt-1">
        <v-row class="mt-2">
          <v-col cols="12" lg="6">
            <div class="itemBox" v-for="(item , i) in ai_response.books" :key="i">
              <div class="nameOfItem">
                <v-icon color="primary">mdi-book</v-icon>
                کتاب {{ item.name }}
              </div>
              <div class="descOfItem">
                {{ item.description }}
              </div>
              <div class="authorOfItem mt-2">
                نویسنده :
                {{ item.author }}
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="6">
            <div class="itemBox" v-for="(item , i) in ai_response.movies" :key="i">
              <div class="nameOfItem">
                <v-icon color="primary">mdi-film</v-icon>
                فیلم {{ item.name }}
              </div>
              <div class="descOfItem">
                {{ item.description }}
              </div>
              <div class="authorOfItem mt-2">
                کارگردان :
                {{ item.director_name }}
              </div>

            </div>
          </v-col>
        </v-row>
      </div>

    </div>
    <br>
    <br>
    <SolutionsProvidedBeck v-if="score > 0 && score >= 13"/>
    <br>
    <br>
    <div class="contentArea">
      <div class="textInterpretation mt-10">
        <div class="anotherTest mt-4" v-if="score <= 13">
          <div class="d-flex justify-space-between flex-grow-1 "
               :class="$vuetify.breakpoint.mdAndDown && 'flex-wrap  '">
            <div class="d-flex justify-space-around flex-column pr-12">
              <div>
                <div class="titleAnotherTest text-right">
                  درباره روابط عاطفی خود بیشتر و بهتر بدانید
                </div>
                <div class="subtitleAnotherTest text-right">
                  تفسیر روابط عاشقانه با بررسی سه جنبه مختلف
                </div>
                <a href="https://app.simiaroom.com/fa/test/7">
                  <v-btn text class="actionAnotherTest text-right">
                    تست مثلث عشق
                    <v-icon color="#4576d8">mdi-arrow-left</v-icon>
                  </v-btn>
                </a>
              </div>
            </div>
            <div>
              <img src="../../../../assets/tests/Test.jpg" alt="">
            </div>
          </div>
        </div>
        <div class="anotherTest mt-4 mb-10" v-else>
          <div class="d-flex justify-space-between flex-grow-1 "
               :class="$vuetify.breakpoint.mdAndDown && 'flex-wrap  '">
            <div class="d-flex justify-space-around flex-column pr-12">
              <div>
                <div class="titleAnotherTest text-right">
                  جعبه ابزار افسردگی
                </div>
                <div class="subtitleAnotherTest text-right">
                  جعبه ابزار افسردگی سیمیاروم با هدف آگاهانه رو به رو شدن با افسردگی، طبق استانداردهای علمی روانشناسی
                  توسط کارشناسان سیمیاروم طراحی و اجرا شده و به صورت کاملا رایگان در دسترس شما قرار گرفته است

                </div>
                <a href="https://simiaroom.com/depression-self-care/">
                  <v-btn text class="actionAnotherTest text-right" small>
                    جعبه ابزار افسردگی
                    <v-icon color="#4576d8" small>mdi-arrow-left</v-icon>
                  </v-btn>
                </a>
              </div>
            </div>
            <div>
              <img src="../../../../assets/beck/1212113.jpg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
    <div class="contentArea ">
      <SuggestTherapists class="SuggestTherapists" v-if="score > 0 && score >= 13"/>
    </div>
    <br>
    <!--    <div class="mt-10">-->
    <!--      <RegisteringSection/>-->
    <!--    </div>-->
    <div style="margin-top: 100px">
      <InterpretationRequest/>
    </div>
    <br>
    <br>
    <br>
  </div>
</template>

<script>
import {VueSvgGauge} from 'vue-svg-gauge'
import ImprovementHint from "@/components/Test/Beck/improvmentHint";
import PriceBoxBeck from "@/components/Test/Main/PriceBoxBeck";
import FeaturesSimiaBeck from "@/components/Test/Beck/FeaturesSimiaBeck";
import SuggestTherapists from "@/components/Test/Beck/SuggestTherapists";
import TestimonialsOnboarding from "@/components/MatchMaking/TestimonialsOnboarding";
import EmojiAreaBeck from "@/components/Test/Beck/EmojiAreaBeck";
import VueHtml2pdf from 'vue-html2pdf'
import BeckInterpretationPDF from "@/components/Test/PDF/BeckInterpretationPDF";
import ConvertToPdf from "@/components/Test/Main/ConvertPdf";
import SolutionsProvidedBeck from "@/components/Test/Beck/SolutionsProvidedBeck";
import RegisteringSection from "@/components/Test/Main/RegisteringSection";
import InterpretationRequest from "@/components/Test/Beck/InterpretationRequest";

/*eslint-disable*/
export default {
  props: {
    report: Object,
    ai_response: {
      required: true
    },
  },
  components: {
    InterpretationRequest,
    RegisteringSection,
    SolutionsProvidedBeck,
    ConvertToPdf,
    BeckInterpretationPDF,
    EmojiAreaBeck,
    TestimonialsOnboarding,
    SuggestTherapists,
    FeaturesSimiaBeck,
    PriceBoxBeck,
    ImprovementHint,
    VueSvgGauge,
    VueHtml2pdf
  },
  data() {
    return {}
  },
  computed: {
    score() {
      return this.report.result[0].score.sum;
    },
    gaugeColor() {
      if (this.score >= 0 && this.score <= 13) {
        return '#76d987'
      } else if (this.score >= 14 && this.score <= 19) {
        return '#6ec9b5'
      } else if (this.score >= 20 && this.score <= 28) {
        return '#76bfd9'
      } else {
        return '#7695d9'
      }
    },
    titleInterpretation() {
      if (this.score >= 0 && this.score <= 13) {
        return 'عدم افسردگی'
      } else if (this.score >= 14 && this.score <= 19) {
        return 'افسردگی خفیف'
      } else if (this.score >= 20 && this.score <= 28) {
        return 'افسردگی متوسط'
      } else {
        return 'افسردگی شدید'
      }
    },
    emojiInterpretations() {
      if (this.score >= 0 && this.score <= 13) {
        return require('../../../../assets/tests/noafsorde.png')
      } else if (this.score >= 14 && this.score <= 19) {
        return require('../../../../assets/tests/litterAfsorder.svg')
      } else if (this.score >= 20 && this.score <= 28) {
        return require('../../../../assets/tests/mediumAfsorder.svg')
      } else {
        return require('../../../../assets/tests/afsorde.svg')
      }
    },
    isLoggedIn() {
      return this.$store.getters.getIsLoggedIn
    }
  },
  methods: {
    getAiResponse() {
      this.$emit('getAiResponse')
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .contentArea {
    width: 90% !important;
  }

  .hintArea {
    width: 100% !important;
  }
}

.itemBox {
  padding: 15px;
  border: 1px solid #dddddd;
  border-radius: 10px;
  margin-bottom: 20px;
  min-height: 150px;
}

.general_ai_interpretation {
  line-height: 30pt;
  color: #6d6d6d;
}

.nameOfItem {
  font-weight: bolder;
  text-align: right;
}

.authorOfItem {
  font-weight: lighter;
  font-size: 10px;
  text-align: right;
  color: grey;
}

.descOfItem {
  font-weight: lighter;
  font-size: 12px;
  text-align: right;
  margin-top: 5px;
  color: #6d6d6d;
  line-height: 25px;
}

.contentArea {
  width: 60%;
  margin: 0px auto;
}

.contentAreaPdf {
  width: 90%;
  margin: 0px auto;
}

.labelAi {
  width: 200px;
  color: white;
  font-size: 15px;
  border-radius: 10px;
  background: #1cba88;
  padding: 10px;
  position: absolute;
  right: 0px;
  top: -25px;
}

.charting {
  position: relative;
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 0px 40px 40px 40px;
}

.gauge {
  width: 300px;
  height: 150px;
  /*margin: 50px auto -20px auto;*/
  margin: 0px auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.gaugeInfo {
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  font-size: 36px;
}

.gaugeInfoMin {
  bottom: -100px;
}

.gaugeInfo > .hint {
  font-size: 10px;
  color: #6d6d6d;
}

.emojiArea {
  margin: 0px auto;
  width: 200px;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #76d987;
}

.hintArea {
  color: #6d6d6d;
  width: 65%;
  background: #fbfbfb;
  text-align: right;
  margin: 50px auto 0px auto;
  padding: 20px;
}

.textInterpretation .textInterpretationTitle {
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000;
}

.shortInterpretation {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #6d6d6d;
  text-align: justify;
}

.shortInterpretation > .titleShortInterpretation {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  margin-bottom: 10px;
}

.shortInterpretation > .hintShortInterpretation {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  width: 80%;
  margin: 20px auto 0px auto;
  padding: 0px 10px;
  border-right: 2px solid #3cbe8b;
}

.singleInterpretation {
  margin-top: 40px;
}

.sideBarSticky {
  position: sticky;
  top: 20px
}

.sidebarItem {
  border-right: 2px solid #dbdbdb;
}

.sidebarItem ul {
  list-style: none;
}

.sidebarItem ul li {
  margin-top: 10px;
  padding: 0px 10px;
}

.activeLi {
  border-right: 2px solid #4576d8;
  color: #4576d8;
}

.reTestBtn {
  width: 260px !important;
  height: 54px !important;
  border-radius: 10px !important;
  margin: 0px auto !important;
  font-size: 21px;
}

.anotherTest {
  background: white;
}

.anotherTest img {
  width: 100%;
}

.titleAnotherTest {
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.subtitleAnotherTest {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.57;
  letter-spacing: normal;
  text-align: center;
  color: #6d6d6d;
  width: 350px;
}

.actionAnotherTest {
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #4576d8;
}

a {
  text-decoration: none;
}
</style>